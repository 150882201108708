<template>
  <div
    v-if="show"
    ref="confimration_modal"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__confirm"
    id="confirmModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="w-100 h-100 d-flex">
      <div class="modal__dialog" role="document">
        <div class="modal__header mb-3">
          <div class="d-flex align-items-center justify-content-between">
            <span class="modal__header__title text--capital">
              Transaction
            </span>
            <a
              class="modal__close modal__close--white d-flex align-items-center justify-content-between"
              @click="$emit('close')"
            >
              <span class="text--capital text--regular"> close </span>
              <ion-icon name="close-outline"></ion-icon>
            </a>
          </div>
          <span class="modal__header__subtitle text--capital"> </span>
        </div>
        <div class="modal__content">
          <div class="modal__body">
            <div class="account_item_head">
              <p class="account_item_head_text text--white">general</p>
            </div>
            <div class="row no-gutters account_item align-items-center">
              <div class="col-6">
                <span
                  class="text--black text--opaq2 text--small text--bold account_item_title"
                >
                  Type
                </span>
              </div>
              <div class="col-6">
                <span
                  class="text--black text--regular account_item_subtitle overlap"
                >
                  {{ accountDetails.transactionType }}
                </span>
              </div>
            </div>
            <div class="row no-gutters account_item align-items-center">
              <div class="col-6">
                <span
                  class="text--black text--opaq2 text--small text--bold account_item_title"
                >
                  Creation Date
                </span>
              </div>
              <div class="col-6">
                <span
                  class="text--black text--regular account_item_subtitle overlap"
                >
                  {{ accountDetails.createdDate | moment }}
                </span>
              </div>
            </div>
            <div class="row no-gutters account_item align-items-center">
              <div class="col-6">
                <span
                  class="text--black text--opaq2 text--small text--bold account_item_title"
                >
                  Value Date (Entry Date)
                </span>
              </div>
              <div class="col-6">
                <span
                  class="text--black text--regular account_item_subtitle overlap"
                >
                  {{ accountDetails.valuedDate | moment }}
                </span>
              </div>
            </div>
            <div class="row no-gutters account_item align-items-center">
              <div class="col-6">
                <span
                  class="text--black text--opaq2 text--small text--bold account_item_title"
                >
                  Amount
                </span>
              </div>
              <div class="col-6">
                <span
                  class="text--black text--regular account_item_subtitle overlap"
                >
                  {{ accountDetails.amount | currency(currencySymbol) }}
                </span>
              </div>
            </div>
            <div class="row no-gutters account_item align-items-center">
              <div class="col-6">
                <span
                  class="text--black text--opaq2 text--small text--bold account_item_title"
                >
                  Account Name
                </span>
              </div>
              <div class="col-6">
                <span
                  class="text--black text--regular account_item_subtitle overlap"
                >
                  {{ accountDetails.accountHolderName }}
                </span>
              </div>
            </div>
            <div
              v-if="
                accountDetails.transactionType &&
                accountDetails.transactionType.toLowerCase() == 'transfer'
              "
              class="row no-gutters account_item align-items-center"
            >
              <div class="col-6">
                <span
                  class="text--black text--opaq2 text--small text--bold account_item_title"
                >
                  {{
                    accountDetails.transactionMode.toLowerCase() == "debit"
                      ? "Transfer To"
                      : "Transfer From"
                  }}
                </span>
              </div>
              <div class="col-6">
                <span
                  class="text--black text--regular account_item_subtitle overlap"
                >
                  {{
                    accountDetails.transactionMode.toLowerCase() == "debit"
                      ? accountDetails.customerAccountTransferToName
                      : accountDetails.customerAccountTransferFromName
                  }}
                </span>
              </div>
            </div>
            <div class="row no-gutters account_item align-items-center">
              <div class="col-6">
                <span
                  class="text--black text--opaq2 text--small text--bold account_item_title"
                >
                  Total Balance
                </span>
              </div>
              <div class="col-6">
                <span
                  class="text--black text--regular account_item_subtitle overlap"
                >
                  {{ accountDetails.balance | currency(currencySymbol) }}
                </span>
              </div>
            </div>
            <div class="row no-gutters account_item align-items-center">
              <div class="col-6">
                <span
                  class="text--black text--opaq2 text--small text--bold account_item_title"
                >
                  User
                </span>
              </div>
              <div class="col-6">
                <span
                  class="text--black text--regular account_item_subtitle overlap"
                >
                  {{ accountDetails.user }}
                </span>
              </div>
            </div>
            <div class="account_item_head">
              <p class="account_item_head_text text--white">Note</p>
            </div>
            <div class="row no-gutters account_item align-items-center">
              <div class="col-12">
                <span
                  class="text--black text--opaq2 text--small text--bold account_item_title overlap"
                >
                  {{ accountDetails.note }}
                </span>
              </div>
            </div>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="$emit('close')"
              >
                cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "ViewTransaction",
  props: {
    show: Boolean,
    close: Function,
    currencySymbol: String,
    accountDetails: [Array, Object],
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
};
</script>

<style scoped>
.overlap {
  word-wrap: break-word;
  width: 90%;
}
</style>
